<template>
  <div class="header">
    <!-- 顶部 -->
    <div class="breadcrumb">
      <el-breadcrumb separator="|" style="color: aliceblue;">
        <template >
          <el-breadcrumb-item><i class="el-icon-user-solid"></i> <span> admin </span></el-breadcrumb-item>
          <el-breadcrumb-item  > <span  @click="loginOut"> 退出 </span></el-breadcrumb-item>
        </template>
        
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return{
      username: ''
    }
  },
  methods: {
    cancellation(command) {
      if(command === 'personal') {
        this.$router.push(command);
      } else if(command === 'back') {
        sessionStorage.clear();
        this.$router.push('/');
      } 
    },
    loginOut(){
      sessionStorage.clear();
      this.$router.push('/');
    }
  },
  created() {
    this.username = JSON.parse(sessionStorage.getItem('userInfo')).name;
  }
};
</script>

<style lang="scss" scoped>
.header {
  // box-shadow: -1px -1px 1px 0px #DAEEFC, 1px 1px 1px 0px #DAEEFC;
  display: flex;
  justify-content:flex-end;
  padding: 0 20px;
  height: 35px;
  .breadcrumb {
    // line-height: 80px;
    // height: 60px;
    // font-size: 12px;
    display: flex;
    align-items: center;
    .el-icon-user-solid,.el-icon-delete{
      color: #fff;
    }
    span{
      font-size: 14px;
      color: white;
    }
    span:hover{
      cursor: pointer;
    }
  }
  .userInfo{
    display: flex;
    align-items: center;
    // height: 60px;
    span{
      color: white;
    }
    .userName:hover{
      font-size: 18px;
      font-weight: 600;
    }
    .img{
      // display: inline-block;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      background:#F2FAF1;
    }
  }
}
</style>