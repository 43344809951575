<template>
  <div class="menu">
    <!-- 菜单 -->
    <!-- <el-menu class="el-menu-vertical-demo" :default-active="routerPath()" :router="true" background-color="#17520F"
      width="200px" :unique-opened="true" text-color="#fff" active-text-color="#17520F">
      <el-submenu v-for="item in menuData" :key="item.id" :index="item.url">
        <span slot="title">{{ item.name }}</span>
        <template v-if="item.children">
          <el-menu-item :index="ele.url" v-for="ele in item.children" :key="ele.id">{{ ele.name }}</el-menu-item>
        </template>
      </el-submenu> -->
      <el-menu class="el-menu-vertical-demo" :default-active="routerPath()" 
       :router="true" background-color="#17520F"
        width="200px" 
       :unique-opened="true"
        text-color="#fff" 
        active-text-color="#17520F">
        <el-menu-item  v-for="item in menuData" :index="item.url" :title="item.name"  :key="item.id">
          {{ item.name }}
        </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuData: [
        // { id: 1, title: '租户功能', url: '/tenant' },
        // {
        //   id: 2, name: '数据处理', url: '/home', children: [
        //      { id: 3, name: '文件上传', url: '/home/upfile' },
        //      { id: 4, name: '数据统计', url: '/home/statistics' },
        //     //  { id: 4, name: '个人中心', url: '/home/personal' },
        //   ]
        // },
        { id: 3, name: '数据列表', url: '/home/userdata' },
        { id: 4, name: '数据统计', url: '/home/statistics' },
        { id: 5, name: '患者信息', url: '/home/userlist' },
        { id: 6, name: '上传记录', url: '/home/upfilelog' },
        { id: 7, name: '文件上传', url: '/home/upfile' },
      ]
    }
  },
  methods: {
    routerPath() {
       if(this.$route.path){
        return this.$route.path
       }else{
        return '/home/userdata'
       }
      }
  },
  created() {
    // this.menuData = JSON.parse(sessionStorage.getItem('userInfo')).menuList;
    // console.log(JSON.parse(sessionStorage.getItem('userInfo')).menuList);
  }
}
</script>

<style scoped lang="scss">
.menu {
  box-shadow: 2px 0px 2px 0px rgba(3, 31, 48, 0.17);

  .el-menu-item.is-active {
    background: #F2FAF1 !important;
    // background-color: var(--primary);
    //background: var(--theme-color)
  }

  ::v-deep.el-menu-vertical-demo.el-menu {
    border: 0px !important;

  }

  ::v-deep.el-submenu__title i {
    color: white !important;
  }
}
</style>